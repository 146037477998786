import React from "react";
import { Box, HStack } from "@chakra-ui/react";
import { HeartFilled } from "@ant-design/icons";
import { TwitterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const twitterProfileUrl = "https://twitter.com/Ethroyce";

const SiteFooter = () => {

  return (
    <Box my={5} fontSize="sm" position="fixed" bottom="1rem" left="1rem" right="1rem">
      <HStack justifyContent="center" spacing="10px">
        <HStack alignItems="center" spacing="7px">
        <div>
        <a href={twitterProfileUrl} target="_blank" rel="noopener noreferrer">
            <TwitterOutlined style={{ fontSize: "24px" }} />
        </a>
        </div>
        </HStack>
        <p>|</p>
        <HStack alignItems="center" spacing="5px">
          <HStack style={{display:"none"}} alignItems="center">
            <span>Built with</span> <HeartFilled /> <span>at</span>
          </HStack>
          <Link style={{display:"none"}} href="https://buidlguidl.com/" >
            BuidlGuidl
            <p>|</p>
          </Link>
          <Link to="/faq">FAQ </Link>
        </HStack>
      </HStack>
    </Box>
  );
};

export default SiteFooter;