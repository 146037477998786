import { Skeleton, Typography } from "antd";
import React from "react";
import Blockies from "react-blockies";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useLookupAddress } from "eth-hooks/dapps/ens";

// changed value={address} to address={address}

/*
  ~ What it does? ~

  Displays an address with a blockie image and option to copy address

  ~ How can I use? ~

  <Address
    address={address}
    ensProvider={mainnetProvider}
    blockExplorer={blockExplorer}
    fontSize={fontSize}
  />

  ~ Features ~

  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
  - Provide fontSize={fontSize} to change the size of address text
*/

const { Text } = Typography;

const blockExplorerLink = (address, blockExplorer) => `${blockExplorer || "https://etherscan.io/"}address/${address}`;

export default function AddressToENS(props) {
  const { currentTheme } = useThemeSwitcher();
  const address = props.value || props.address;
  const ens = useLookupAddress(props.ensProvider, address);
  const ensSplit = ens && ens.split(".");
  const validEnsCheck = ensSplit && ensSplit[ensSplit.length - 1] === "eth";
  const etherscanLink = blockExplorerLink(address, props.blockExplorer);
  let displayAddress = address?.substr(0, 5) + "..." + address?.substr(-4);

  if (validEnsCheck) {
    displayAddress = ens;
  } else if (props.size === "short") {
    displayAddress += "..." + address.substr(-4);
  } else if (props.size === "long") {
    displayAddress = address;
  }

  if (!address) {
    return (
      <span>
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </span>
    );
  }

  const maxLength = 35;
  const website = "jpegMe.com";

  function parseMessage(message) {
    let msgBytes = new TextEncoder().encode(message);
    let line = "";
    
    if(msgBytes.length == 0) {
      // no text to parse
      return ["", ""];
    }
  
    if(msgBytes.length < maxLength) {
      // last line of message so return it as the last line
      return ["", message];
    }
  
    for(let i = maxLength; i > 0; i--) {
      // multiple lines remaining
      if(msgBytes[i] == 32) {
        line = message.substring(0, i);
        message = message.substring(i + 1);
        break;
      }
      if(i == 1) {
        //console.log("reached one");
        line = message.substring(0, maxLength);
        message = message.substring(maxLength);
      }
    }
    return [message, line];
  }
  
  function endOfString(str, startIndex) {
    return str.substring(str.length - startIndex, str.length);
  }
  
  function substring(str, startIndex, endIndex) {
    return str.substring(startIndex, endIndex);
  }
  
  function toAsciiString(x) {
    let s = "";
    for(let i = 0; i < 20; i++) {
      let b = String.fromCharCode((x / (2 ** (8 * (19 - i)))) & 255);
      let hi = String.fromCharCode(parseInt(b, 16) / 16);
      let lo = String.fromCharCode(parseInt(b, 16) - 16 * parseInt(hi, 16));
      s += hi + lo;
    }
    return s;
  }
  
  function char(b) {
    if (b < 10) {
      return String.fromCharCode(b + 48);
    } else {
      return String.fromCharCode(b + 87);
    }
  }
  function textToBase64(text) {
    const bytes = new TextEncoder().encode(text);
    const base64 = btoa(String.fromCharCode(...bytes));
    return base64;
  }



  //SVG strings contain the constant components of the generatice message graphics code
  const svg1 = '<svg xmlns="http://www.w3.org/2000/svg" width="350" height="350">  <style>  .text { font-family: "Source Code Pro",monospace; font-size: 14px; text-wrap:200px; } .sender {font-size: 20px; font-weight:bold} .msgText{fill: white; } .reply {stroke-width:1;stroke:rgb(0,168,255); fill:white} .fill {fill:url(#grad1)} </style>  <rect width="100%" height="100%" fill="white" />    <defs>     <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">       <stop offset="0%" style="stop-color:rgb(58, 208, 91 )" />       <stop offset="100%" style="stop-color:rgb(0,168,255)" />     </linearGradient>   </defs>  <rect class="fill" width="320" height="200" x="15" y="15" rx="10" ry="10" />';
  const svg2 = '<polygon points="320,215 300,215 297,230" style="fill:rgb(0,168,255)" /> <text class="text sender fill" x="320" y="250"  text-anchor="end" >';
  const svg3 = '</text> <a href="https://www.jpegMessage.me" target="_blank"> <rect class="reply" width="320" height="30" x="15" y="300" rx="5" ry="5" /> <text class="text" fill="rgb(0,168,255)" x="30" y="320" font-weight="bold" font-style="italic" >Reply online @ ';
  const svg4 = '</text> <text class="text sender" fill="rgb(0,168,255)" x="325" y="321" text-anchor="end" >></text></a></svg>';
  const text1 = '<text x="27" y="40" class="msgText text">';
  const text2 = '<text x="27" y="60" class="msgText text">';
  const text3 = '<text x="27" y="80" class="msgText text">';
  const text4 = '<text x="27" y="100" class="msgText text">';
  const text5 = '<text x="27" y="120" class="msgText text">';
  const textPost = '</text>';

  function buildImage(_message, _sender) {
    // create message lines
    let line1 = '';
    let line2 = '';
    let line3 = '';
    let line4 = '';
    let line5 = '';
  
    // break message up into lines
    [_message, line1] = parseMessage(_message);
    [_message, line2] = parseMessage(_message);
    [_message, line3] = parseMessage(_message);
    [_message, line4] = parseMessage(_message);
    [_message, line5] = parseMessage(_message);
  
    // combine lines with SVG tags into one string
    _message = `${text1}${line1}${textPost}`;
    _message = `${_message}${text2}${line2}${textPost}`;
    _message = `${_message}${text3}${line3}${textPost}`;
    _message = `${_message}${text4}${line4}${textPost}`;
    _message = `${_message}${text5}${line5}${textPost}`;
  
    // Finish compiling image
    let image =  "data:image/svg+xml;base64," + textToBase64(`${svg1}${_message}${svg2}${displayAddress}${svg3}${website}${svg4}`);
    return image;
  }

  const src =  buildImage(props.message, props.address);

  return <img id="preview" src={src} alt="NFT Message" />
}
