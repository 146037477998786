import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

const Faq = () => {
  const faqData = [
    {
      question: "How are messages stored?",
      answer: "Message Data is stored in blockchain transaction logs. Log data is stored on Ethereum nodes. It is decentralized, and immutable, and less expensive than data stored as text on chain.",
    },
    {
      question: "Are my messages private?",
      answer: "No, messages are stored in plaintext and are visible as public log data. If private messages is a feature you are interested in please contact me on Twitter.",
    },
    {
      question: "What happens if a wallet receives multiple messages?",
      answer: "In an effort to prevent abuse, each wallet can only receive one NFT message. If a wallet already has a NFT message and receives another, only the latest message will be displayed to that user. However, all prior messages will still be available to be read on this website.",
    },
    {
      question: "Can I unsubscribe from receiving messages?",
      answer: "Yes, you can unsubscribe by burning your message through opensea or any other method and you will not receive another message unless you opt back in through our smart contract. ",
    },
    // Add more FAQ data as needed
  ];

  return (
    <div style={{ maxWidth: "600px", margin: "auto", padding: "8px" }}>
      <h2 className="title" style={{ marginBottom: "16px" }}>Frequently Asked Questions</h2>
      <Collapse defaultActiveKey={["0"]} accordion>
        {faqData.map((faq, index) => (
          <Panel header={faq.question} key={index}>
            <p>{faq.answer}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default Faq;
