import React from "react";
import AddressToENS from "../components/AddressToENS";
import { useLocation } from "react-router-dom";

export default function NFTsvg({
  mainnetProvider,
  blockExplorer 
}) {

  const location = useLocation()

  const queryParameters = new URLSearchParams(location.search)

  const message = queryParameters.get("message");
  const sender = queryParameters.get("sender");

  return (
    <div>
      <p>Message: {queryParameters.get("message")}</p>
      <p>Sender: {queryParameters.get("sender")}</p>
      <AddressToENS message = {message} address={sender} ensProvider={mainnetProvider} blockExplorer={blockExplorer} />
    </div>
  );
}
